.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 300px;
  background-color: #FFF;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  border: 1px solid rgba(0,60,136,1);
  /* pointer-events: none; */
}

.layers_menu_content {
  margin: 30px 0 10px 0;
}

.layer_toggle {
  padding: 0 0 0 10px;
}

.layer_toggle button {
  color: #FFF;
  background-color: rgba(0,60,136,0.7);
}

.group_toggle {
  border-bottom: 1px solid black;
  padding: 10px 0 10px 10px;
}

.group_toggle input {
  cursor: pointer;
}

.group_toggle:nth-last-child(1) {
  border: none;
}

.zoom_to_layer{
  /* display: inline-block; */
  float: right;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: 1px solid rgba(0,60,136,0.7);
  border-radius: 3px;
  margin-left: 6px;;
  cursor: pointer;
}
.zoom_to_layer img {
  filter: invert(100%);
}

.close_menu {
  position: relative;
  width: 100%;
  height: 100%;
  /* pointer-events: none; */
  cursor: pointer;
}
.close_menu > div {
  border-radius: 5px;
  color: #FFF;
  text-align: center;
  background-color: rgba(0,60,136,0.7);
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.open_menu {
  color: #FFF;
  background-color: rgba(0,60,136,0.7);
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  top: 10px;
  left: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.open_menu img {
  filter: invert(100%);
}
