.ol-map {
    min-width: 600px;
    /* min-height: 500px; */
    /* margin: 50px; */
    height: 600px;
    width: 100%;
    overflow: hidden;
}

.ol-control {
    position: absolute;
    background-color: rgba(255,255,255,0.4);
    border-radius: 4px;
    padding: 2px;
}

.ol-full-screen {
    top: .5em;
    right: .5em;
}

.ol-scale-line {
    position: absolute;
    background: rgba(0,60,136,0.3);
    border-radius: 4px;
    bottom: 8px;
    left: 8px;
    padding: 2px;
    position: absolute;

}

.ol-scale-line-inner {
    border: 1px solid #000;
    border-top: none;
    color: #000;
    font-size: 10px;
    text-align: center;
    margin: 1px;
    will-change: contents, width;
    transition: all 0.25s;
}
